/* eslint-disable no-console */
import { updateOneUserProfile } from '../API/usersAPI';
import {
  getFirebaseToken,
  requestNotificationPermission,
  // messaging,
  // onMessaging,
} from '../config/firebase';

const updateFirebaseToken = async (fcmToken, userStoreToken) => {
  await updateOneUserProfile(userStoreToken, {
    firebaseToken: fcmToken,
  }).catch((err) => {
    console.log(err);
  });
};

const updateAlternateFirebaseToken = async (fcmToken, userStoreToken) => {
  await updateOneUserProfile(userStoreToken, {
    alternateFirebaseToken: fcmToken,
  }).catch((err) => {
    console.log(err);
  });
};

const isNullOrUndefinedOrEmpty = (str) => !str || str.trim().length === 0;

const shouldUpdateTokens = async (newToken, user) => {
  if (!user || !newToken) {
    return false;
  }

  // Case 1: Both tokens are null
  if (user?.firebaseToken === null && user?.alternateFirebaseToken === null) {
    return true;
  }

  // Case 2: New token match with one of the token
  if (
    newToken === user?.firebaseToken ||
    newToken === user?.alternateFirebaseToken
  ) {
    return false;
  }

  // Case 3: One of the token is null
  if (user?.firebaseToken === null || user?.alternateFirebaseToken === null) {
    return true;
  }

  // Case 4: New token doesn't match either token
  if (
    newToken !== user?.firebaseToken ||
    newToken !== user?.alternateFirebaseToken
  ) {
    return true;
  }

  return false;
};

const updateFirebaseTokens = async (newToken, user, userStoreToken) => {
  if (!user || !newToken) {
    return true;
  }

  // Edge Case: New token match with one of the present token
  if (
    newToken === user?.firebaseToken ||
    newToken === user?.alternateFirebaseToken
  ) {
    return true;
  }

  // Case 1: Both tokens are not present
  if (
    isNullOrUndefinedOrEmpty(user?.firebaseToken) &&
    isNullOrUndefinedOrEmpty(user?.alternateFirebaseToken)
  ) {
    await updateFirebaseToken(newToken, userStoreToken).catch((error) => {
      console.log(error);
    });
    return true;
  }

  // Case 2: One of the token is not present
  if (
    isNullOrUndefinedOrEmpty(user?.firebaseToken) &&
    newToken !== user?.alternateFirebaseToken
  ) {
    await updateFirebaseToken(newToken, userStoreToken).catch((error) => {
      console.log(error);
    });
    return true;
  }
  if (
    isNullOrUndefinedOrEmpty(user?.alternateFirebaseToken) &&
    newToken !== user?.firebaseToken
  ) {
    await updateAlternateFirebaseToken(newToken, userStoreToken).catch(
      (error) => {
        console.log(error);
      }
    );
    return true;
  }

  // Case 3: Both token present and New token doesn't match either token
  if (
    newToken !== user?.firebaseToken &&
    newToken !== user?.alternateFirebaseToken
  ) {
    if (!isNullOrUndefinedOrEmpty(user?.firebaseToken)) {
      await updateAlternateFirebaseToken(
        user?.firebaseToken,
        userStoreToken
      ).catch((error) => {
        console.log(error);
      });
    }
    await updateFirebaseToken(newToken, userStoreToken).catch((error) => {
      console.log(error);
    });
    return true;
  }

  return true;
};

const setupFcmToken = async (userStoreSession, userStoreToken) => {
  const permissionGranted = await requestNotificationPermission()
    .then((permission) => permission)
    .catch((error) => {
      console.log(error);
    });
  const newFcmToken = await getFirebaseToken((token) => token).catch(
    (error) => {
      console.log(error);
    }
  );

  if (permissionGranted) {
    if (newFcmToken) {
      const shouldUpdate = await shouldUpdateTokens(
        newFcmToken,
        userStoreSession
      ).catch((error) => {
        console.log(error);
      });

      // Check if update is needed
      if (!shouldUpdate) {
        return true;
      }

      await updateFirebaseTokens(
        newFcmToken,
        userStoreSession,
        userStoreToken
      ).catch((error) => {
        console.log(error);
      });

      // if (messaging) {
      //   onMessaging(messaging, (payload) => {
      //     toast.info(
      //       `${payload.notification.title}: ${payload.notification.body}`
      //     );
      //   });
      // }
    }
  }
  return true;
};

export default setupFcmToken;
