/* eslint-disable no-console */
import { useEffect, useState } from 'react';
import io from 'socket.io-client';

const socketOptions = {
  transports: ['websocket'],
  reconnection: true,
  reconnectionAttempts: 100,
  reconnectionDelay: 1000,
  reconnectionDelayMax: 5000,
  randomizationFactor: 0.5,
  timeout: 20000,
};

const useWebSocket = (user) => {
  const [socket, setSocket] = useState(null);
  const [isConnected, setIsConnected] = useState(false);

  useEffect(() => {
    if (!user || socket) return; // Prevent creating a socket if one already exists

    // Setup socket.io client
    const newSocket = io(process.env.REACT_APP_WEBSOCKET_URL, socketOptions);
    // Set new socket state
    setSocket(newSocket);

    // Handle socket errors
    newSocket.on('error', () => {
      // Set connection status
      setIsConnected(false);
      // console.log('Socket error on Websocket Client:', error);
    });

    // Handle connection success
    newSocket.on('connect', () => {
      // Set connection status
      setIsConnected(true);
      // Authenticate user after connection
      newSocket.emit('authenticate', user?.userUID);
      // console.log('Connected to WebSocket server');
    });

    // Handle connection errors
    newSocket.on('connect_error', () => {
      // console.error('Connection error:', error);
    });

    newSocket.on('disconnect', () => {
      // Set connection status
      setIsConnected(false);
      // console.log('Disconnected from WebSocket server');
    });

    newSocket.on('reconnect_attempt', () => {
      // console.log('Attempting to reconnect...');
    });

    // eslint-disable-next-line consistent-return
    return () => {
      newSocket.disconnect();
      newSocket.off('connect');
      newSocket.off('disconnect');
      newSocket.off('error');
      newSocket.off('connect_error');
      newSocket.off('reconnect_attempt');
      setSocket(null);
    };
  }, [user]);

  return { socket, isConnected };
};

export default useWebSocket;
