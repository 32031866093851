/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  userSessionStoreData: null,
  userTokenStoreData: null,
  userSessionIdStoreData: null,
  userFirstTimeLogin: false,
};

const UserSessionReducer = createSlice({
  name: 'USER-STORE-DATA',
  initialState,
  reducers: {
    userSessionAction: (state, action) => {
      state.userSessionStoreData = action.payload;
    },
    userTokenAction: (state, action) => {
      state.userTokenStoreData = action.payload;
    },
    userSessionIdAction: (state, action) => {
      state.userSessionIdStoreData = action.payload;
    },
    userLogoutAction: (state) => {
      state.userTokenStoreData = null;
      state.userSessionStoreData = null;
      state.userSessionIdStoreData = null;
    },
    userFirstTimeAction: (state, action) => {
      state.userFirstTimeLogin = action.payload;
    },
  },
});

export const {
  userSessionAction,
  userTokenAction,
  userLogoutAction,
  userFirstTimeAction,
  userSessionIdAction,
} = UserSessionReducer.actions;

export default UserSessionReducer.reducer;
