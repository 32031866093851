/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  showNotificationID: null,
  viewNotifications: false,
  currentMessage: null,
};

const NotificationReducer = createSlice({
  name: 'SHOW-NOTIFICATION-UPDATE',
  initialState,
  reducers: {
    notificationClickAction: (state, action) => {
      state.showNotificationID = action.payload;
    },
    notificationViewAction: (state, action) => {
      state.viewNotifications = action.payload;
    },
    currentMessageAction: (state, action) => {
      state.currentMessage = action.payload;
    },
  },
});

export const {
  notificationClickAction,
  notificationViewAction,
  currentMessageAction,
} = NotificationReducer.actions;

export default NotificationReducer.reducer;
