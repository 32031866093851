/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk, isAnyOf } from '@reduxjs/toolkit';
import axios from 'axios';
import { toast } from 'react-toastify';
import { errorHandlerForPromise } from '../utils/errorHandler';

export const initialState = {
  cartItems: [],
  cartBtnStatus: false,
  errorMsg: '',
};

export const newCartAction = createAsyncThunk(
  'newCartActionThunk',
  async () => {
    const res = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/orders/cart`
    );
    return res.data.order;
  }
);

export const getCartAction = createAsyncThunk(
  'getCartActionThunk',
  async (body) => {
    const res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/orders/cart/1`,
      body
    );
    return res.data.order;
  }
);

export const getUserCartAction = createAsyncThunk(
  'getUserCartActionThunk',
  async (token) => {
    const res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/users/1/cart`,
      {},
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return res.data.order;
  }
);

export const AddItemToCart = createAsyncThunk('AddItemToCart', async (body) => {
  await axios.post(
    `${process.env.REACT_APP_BASE_URL}/lineitems/add-to-cart/1`,
    body
  );
});

export const RemoveItemFromCart = createAsyncThunk(
  'RemoveItemFromCart',
  async (body) => {
    const res = await axios.delete(
      `${process.env.REACT_APP_BASE_URL}/lineitems/remove-from-cart/1`,
      {
        data: body,
      }
    );
    if (res.data.success) {
      toast.warn('Item Removed From Cart 🛒');
    }
  }
);

const CartItemReducer = createSlice({
  name: 'CART-ITEM-LIST',
  initialState,
  reducers: {
    cartItemAction: (state, action) => {
      state.cartItems = action.payload;
    },
    cartItemReset: (state) => {
      state.cartItems = [];
    },
  },

  extraReducers: (builder) => {
    builder
      .addMatcher(
        isAnyOf(
          newCartAction.fulfilled,
          getUserCartAction.fulfilled,
          getCartAction.fulfilled
        ),
        (state, action) => {
          state.cartItems = action.payload;
          state.cartBtnStatus = false;
        }
      )
      .addMatcher(
        isAnyOf(
          newCartAction.rejected,
          getUserCartAction.rejected,
          getCartAction.rejected
        ),
        (state, action) => {
          state.errorMsg = action.error;
          state.cartBtnStatus = false;
          errorHandlerForPromise(state.errorMsg);
        }
      )
      .addMatcher(
        isAnyOf(AddItemToCart.fulfilled, RemoveItemFromCart.fulfilled),
        (state) => {
          state.cartBtnStatus = true;
        }
      )
      .addMatcher(
        isAnyOf(AddItemToCart.pending, RemoveItemFromCart.pending),
        (state) => {
          state.cartBtnStatus = true;
        }
      )
      .addMatcher(
        isAnyOf(AddItemToCart.rejected, RemoveItemFromCart.rejected),
        (state) => {
          state.cartBtnStatus = true;
        }
      );
  },
});

export const { cartItemAction, cartItemReset } = CartItemReducer.actions;

export default CartItemReducer.reducer;
