import React from 'react';
import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { SkeletonTheme } from 'react-loading-skeleton';
import { persistor, store } from './store';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'react-loading-skeleton/dist/skeleton.css';
import './index.css';
import './Website.css';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { NotificationProvider } from './utils/notificationContext';

ReactDOM.render(
  <React.StrictMode>
    <SkeletonTheme baseColor='#d9d9d9' highlightColor='#c5c5c5'>
      <BrowserRouter>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <HelmetProvider>
              <NotificationProvider>
                <App />
              </NotificationProvider>
            </HelmetProvider>
          </PersistGate>
        </Provider>
      </BrowserRouter>
    </SkeletonTheme>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();
