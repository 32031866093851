import React, { createContext, useState, useMemo } from 'react';
import PropTypes from 'prop-types';

// Create the context
const NotificationContext = createContext();

// Create the provider component
function NotificationProvider({ children }) {
  const [notificationPopupClick, setNotificationPopupClick] = useState(false);

  const handleNotificationClick = () => {
    setNotificationPopupClick(true);
  };

  const handleNotificationCloseClick = () => {
    setNotificationPopupClick(false);
  };

  const value = useMemo(
    () => ({
      notificationPopupClick,
      handleNotificationClick,
      handleNotificationCloseClick,
    }),
    [notificationPopupClick]
  );

  return (
    <NotificationContext.Provider value={value}>
      {children}
    </NotificationContext.Provider>
  );
}

NotificationProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { NotificationContext, NotificationProvider };
