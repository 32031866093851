import React from 'react';
import PropTypes from 'prop-types';
import { PropagateLoader } from 'react-spinners';

function SpinnerLoader({ isLoading }) {
  return (
    isLoading && (
      <div className='custom-modal-overlay'>
        <div className='custom-modal-content'>
          <PropagateLoader color='#0d94fb' size={20} />
        </div>
      </div>
    )
  );
}

SpinnerLoader.propTypes = {
  isLoading: PropTypes.bool.isRequired,
};

export default SpinnerLoader;
