const productionConfig = {
  apiKey: 'AIzaSyDsvhq9duY6Gzd0HD2-9Mz6lb8VTHn-b-I',
  authDomain: 'intelisys-production.firebaseapp.com',
  projectId: 'intelisys-production',
  storageBucket: 'intelisys-production.appspot.com',
  messagingSenderId: '750590213237',
  appId: '1:750590213237:web:437989521bec02dd068c3c',
  measurementId: 'G-89RZQY15MR',
};

const stagingConfig = {
  apiKey: 'AIzaSyDAh6ZZ4AusqFXh9jR9Y_I0V7TrP34HwBw',
  authDomain: 'intelisys-staging.firebaseapp.com',
  projectId: 'intelisys-staging',
  storageBucket: 'intelisys-staging.appspot.com',
  messagingSenderId: '38658990689',
  appId: '1:38658990689:web:55fca4e512323934920020',
  measurementId: 'G-TQGHW3C8BD',
};

const developmentConfig = {
  apiKey: 'AIzaSyCeiW3a2TA0Wes2cRruhk6bVZYJ-Zu_C7s',
  authDomain: 'intelisys-mobile-app-staging.firebaseapp.com',
  projectId: 'intelisys-mobile-app-staging',
  storageBucket: 'intelisys-mobile-app-staging.appspot.com',
  messagingSenderId: '718457336071',
  appId: '1:718457336071:web:6f11ff6fad7dc8a80855a2',
  measurementId: 'G-RENG5C1NPP',
};

let firebaseConfig;

if (process.env.REACT_APP_NODE_ENV === 'production') {
  firebaseConfig = productionConfig;
} else if (process.env.REACT_APP_NODE_ENV === 'staging') {
  firebaseConfig = stagingConfig;
} else if (process.env.REACT_APP_NODE_ENV === 'development') {
  firebaseConfig = developmentConfig;
}

module.exports = { firebaseConfig };
