import { toast } from 'react-toastify';

const Logout = (data) => {
  if (data === 'reject401') {
    toast.warn('Session Expired. Redirecting To Login Page...');
    localStorage.clear();
    setTimeout(() => {
      window.location.href = '/login';
    }, 1500);
  } else if (data === 'promise') {
    const second5 = new Promise((resolve) => {
      setTimeout(resolve, 5000);
    });
    toast.promise(second5, {
      pending: 'Internal Server Error. Trying To Connect Again...',
      success: '',
      error: '',
    });
    setTimeout(() => {
      window.location.reload();
    }, 5500);
  } else {
    toast.warn('Session Expired. Redirecting To Login Page...');
    localStorage.clear();
    setTimeout(() => {
      window.location.href = '/login';
    }, 1500);
  }
};
export default Logout;
