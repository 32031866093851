/* eslint-disable no-console */
/* eslint-disable import/no-extraneous-dependencies */
import { initializeApp } from 'firebase/app';
import {
  getMessaging,
  getToken,
  onMessage,
  isSupported,
} from 'firebase/messaging';

import { firebaseConfig } from './firebaseConfig';

const vapidKey = process.env.REACT_APP_FIREBASE_VAPID_KEY;

const firebaseApp = initializeApp(firebaseConfig);
export const messaging = isSupported() ? getMessaging(firebaseApp) : false;
export const onMessaging = onMessage;

export const requestNotificationPermission = async () => {
  // Browser do not support notifications
  if (!('Notification' in window)) {
    return false;
  }

  // If permissions already granted
  if (Notification?.permission === 'granted') {
    return true;
  }

  // Request new permission for notification
  let permission = await Notification?.requestPermission().catch((error) => {
    console.log(error);
  });

  // If new permission granted
  if (permission === 'granted') {
    return true;
  }

  // If new permission not granted nor denied request permission again
  if (permission !== 'denied' && permission !== 'granted') {
    permission = Notification?.requestPermission().catch((error) => {
      console.log(error);
    });
    if (permission === 'granted') {
      return true;
    }
  }

  // Request notification permission failed
  return false;
};

export const getFirebaseToken = async () => {
  const token = getToken(messaging, { vapidKey })
    .then((currentToken) => {
      if (currentToken) {
        return currentToken;
      }
      // Show permission request UI if needed
      console.log('No registration token. Request permission to generate one.');
      requestNotificationPermission();
      return null;
    })
    .catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
    });
  return token;
};
