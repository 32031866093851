import axios from 'axios';

export const findAllUsers = (token) =>
  axios.get(`${process.env.REACT_APP_BASE_URL}/users`, {
    headers: {
      Authorization: token,
    },
  });

export const findAllUsersPaginated = (token, body) =>
  axios.post(`${process.env.REACT_APP_BASE_URL}/users/paginated`, body, {
    headers: {
      Authorization: token,
    },
  });

export const findAllUsersSearch = (token, body) =>
  axios.post(`${process.env.REACT_APP_BASE_URL}/users/search`, body, {
    headers: {
      Authorization: token,
    },
  });

export const findOneUser = (token, body) =>
  axios.post(`${process.env.REACT_APP_BASE_URL}/users/${body.userId}`, body, {
    headers: {
      Authorization: token,
    },
  });

export const findOneUserProfile = (token) =>
  axios.post(
    `${process.env.REACT_APP_BASE_URL}/users/1/myprofile`,
    {},
    {
      headers: {
        Authorization: token,
      },
    }
  );

export const updateOneUserProfilePassword = (token, body) =>
  axios.patch(
    `${process.env.REACT_APP_BASE_URL}/users/1/profile/update/password`,
    body,
    {
      headers: {
        Authorization: token,
      },
    }
  );

export const findAllOrders = (token) =>
  axios.post(
    `${process.env.REACT_APP_BASE_URL}/users/1/orders`,
    {},
    {
      headers: {
        Authorization: token,
      },
    }
  );

export const findAllSubscriptions = (token) =>
  axios.post(
    `${process.env.REACT_APP_BASE_URL}/users/1/subscriptions`,
    {},
    {
      headers: {
        Authorization: token,
      },
    }
  );

export const createOneUser = (token, body) =>
  axios.post(`${process.env.REACT_APP_BASE_URL}/users`, body, {
    headers: {
      Authorization: token,
    },
  });

export const createOneUserProfile = (body) =>
  axios.post(`${process.env.REACT_APP_BASE_URL}/users/profile/create`, body);

export const updateOneUser = (token, body) =>
  axios.patch(`${process.env.REACT_APP_BASE_URL}/users/${body.userId}`, body, {
    headers: {
      Authorization: token,
    },
  });

export const updateOneUserProfile = (token, body) =>
  axios.patch(`${process.env.REACT_APP_BASE_URL}/users/1/profile`, body, {
    headers: {
      Authorization: token,
    },
  });

export const deleteOneUser = (token, body) =>
  axios.delete(`${process.env.REACT_APP_BASE_URL}/users/${body.userId}`, {
    headers: {
      Authorization: token,
    },
    data: body,
  });

export const findAllUserActivities = (token, body) =>
  axios.get(
    `${process.env.REACT_APP_BASE_URL}/users/${body.userId}/useractivities`,
    {
      headers: {
        Authorization: token,
      },
      data: body,
    }
  );

export const findUserAllWatchLists = (token) =>
  axios.post(
    `${process.env.REACT_APP_BASE_URL}/users/1/watchlists`,
    {},
    {
      headers: {
        Authorization: token,
      },
    }
  );

export const findOneCart = async (token, body) =>
  axios.post(
    `${process.env.REACT_APP_BASE_URL}/users/${body.userUID}/cart`,
    body,
    {
      headers: {
        Authorization: token,
      },
    }
  );

export const findOneCartUser = async (token, body) =>
  axios.post(
    `${process.env.REACT_APP_BASE_URL}/users/${body.userUID}/cart`,
    body,
    {
      headers: {
        Authorization: token,
      },
    }
  );

export const findAllUserPayouts = async (token, body) =>
  axios.post(`${process.env.REACT_APP_BASE_URL}/users/1/payouts`, body, {
    headers: {
      Authorization: token,
    },
  });

export const generatePayoutRequest = async (token, body) =>
  axios.post(
    `${process.env.REACT_APP_BASE_URL}/users/1/payouts/generate`,
    body,
    {
      headers: {
        Authorization: token,
      },
    }
  );

export const verifyPayoutRequest = async (token, body) =>
  axios.post(`${process.env.REACT_APP_BASE_URL}/users/1/payouts/verify`, body, {
    headers: {
      Authorization: token,
    },
  });

export const findAllReferralsAnalytics = async (token) =>
  axios.post(
    `${process.env.REACT_APP_BASE_URL}/users/1/referrals/analytics`,
    {},
    {
      headers: {
        Authorization: token,
      },
    }
  );

export const findAllReferrals = async (token) =>
  axios.post(
    `${process.env.REACT_APP_BASE_URL}/users/1/referrals`,
    {},
    {
      headers: {
        Authorization: token,
      },
    }
  );
