/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk, isAnyOf } from '@reduxjs/toolkit';
import axios from 'axios';
import { errorHandlerForPromise } from '../utils/errorHandler';

const initialState = {
  allActivePlans: [],
  allActiveAnalysis: [],
  allActiveCalls: [],
  allActiveSegments: [],
  allActiveStocks: [],
  isLoading: false,
  errorMsg: '',
};

export const plansAction = createAsyncThunk('plansActionThunk', async () => {
  const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/plans/active`);
  return res.data.plans;
});

export const analysisAction = createAsyncThunk(
  'analysisActionThunk',
  async () => {
    const res = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/analysis/active`
    );
    return res.data.analyses;
  }
);

export const callsAction = createAsyncThunk('callsActionThunk', async () => {
  const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/calls/active`);
  return res.data.calls;
});

export const segmentsAction = createAsyncThunk(
  'segmentsActionThunk',
  async () => {
    const res = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/segments/active`
    );
    const activeSegments = res.data.segments;
    return activeSegments;
  }
);

export const stocksAction = createAsyncThunk('stocksActionThunk', async () => {
  const res = await axios.get(
    `${process.env.REACT_APP_BASE_URL}/stocks/active`
  );
  const activeStocks = res.data.stocks;
  return activeStocks;
});

const PublicAPIDataReducer = createSlice({
  name: 'PUBLIC-API-DATA',
  initialState,
  reducers: {
    activePlanAction: (state, action) => {
      state.allActivePlans = action.payload;
    },

    activeAnalysisAction: (state, action) => {
      state.allActiveAnalysis = action.payload;
    },

    activeCallsAction: (state, action) => {
      state.allActiveCalls = action.payload;
    },

    activeSegmentsAction: (state, action) => {
      state.allActiveSegments = action.payload;
    },

    activeStocksAction: (state, action) => {
      state.allActiveStocks = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(plansAction.fulfilled, (state, action) => {
        state.allActivePlans = action.payload;
        state.isLoading = false;
      })

      .addCase(segmentsAction.fulfilled, (state, action) => {
        state.allActiveSegments = action.payload;
        state.isLoading = false;
      })

      .addCase(stocksAction.fulfilled, (state, action) => {
        state.allActiveStocks = action.payload;
        state.isLoading = false;
      })

      .addCase(analysisAction.fulfilled, (state, action) => {
        state.allActiveAnalysis = action.payload;
        state.isLoading = false;
      })

      .addCase(callsAction.fulfilled, (state, action) => {
        state.allActiveCalls = action.payload;
        state.isLoading = false;
      })

      .addMatcher(
        isAnyOf(
          plansAction.rejected,
          segmentsAction.rejected,
          stocksAction.rejected,
          analysisAction.rejected,
          callsAction.rejected
        ),
        (state, action) => {
          state.errorMsg = action.error;
          state.isLoading = false;
          errorHandlerForPromise(state.errorMsg);
        }
      )

      .addMatcher(
        isAnyOf(
          plansAction.pending,
          segmentsAction.pending,
          stocksAction.pending,
          analysisAction.pending,
          callsAction.pending
        ),
        (state) => {
          state.isLoading = true;
        }
      );
  },
});

export const {
  activePlanAction,
  activeAnalysisAction,
  activeCallsAction,
  activeSegmentsAction,
  activeStocksAction,
} = PublicAPIDataReducer.actions;

export default PublicAPIDataReducer.reducer;
