import { toast } from 'react-toastify';
import Logout from './logout';

export const errorHandler = async (errorObj) => {
  const { response } = errorObj;
  if (response?.status !== 401) {
    const { data } = response;
    const messages = data?.error?.errors?.map((error) => error.message) || [
      data?.message,
    ];
    messages.forEach((message) => toast.error(message));
  } else {
    Logout();
  }
};

export const errorHandlerForPromise = async (err) => {
  const { message } = err;
  const action = message.includes('401') ? 'reject401' : 'promise';
  Logout(action);
};
